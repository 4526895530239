export default {
  methods: {
    /**
     * Метод форматирует обьект, удаляя свойства с пусными значениями
     * @param {Object} obj - обьект, который необходимо отформатировать
     */
    objectFormatter(obj) {
      const data = { ...obj };
      if (data && Object.keys(data).length) {
        for (const key in data) {
          !data[key] && delete data[key];
        }
      }
      return data;
    },
  },
};
